<template>
  <el-upload
    action
    :on-remove="handleRemove"
    :http-request="upload"
    :file-list="explainFileList"
    :on-preview="handlePreview"
    :show-file-list="false"
  >
    <div class="updateBtn">上传附件</div>
  </el-upload>
</template>

<script>
import { uploadAdminOne } from '@/api/common'
import axios from 'axios'
export default {
  props: {
    lookUrl: {
      type: String
    },
    saveUrl: {
      type: String
    },
    index: {
      type: [String, Number]
    }
  },

  data() {
    return {
      fileList: [],
      // fileArray: [],
      explainFileList: []
    }
  },

  computed: {
    Authorization() {
      return sessionStorage.getItem('token')
    }
  },
  watch: {
    lookUrl: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.updateExplainFileList()
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    updateExplainFileList() {
      if (this.lookUrl) {
        let name = this.lookUrl.substring(this.lookUrl.lastIndexOf('/') + 1)
        name = name.split('?')[0]
        this.explainFileList.push({
          name,
          url: this.lookUrl
        })
        // console.log(this.explainFileList);
        this.$emit('update:explainFileList', this.explainFileList)
        // this.$emit('explainFileList', this.explainFileList)
      } else {
        this.explainFileList = []
      }
    },
    handleRemove() {
      this.explainImgList = []
    },
    async upload(file) {
      // console.log(file);
      this.explainFileList = []
      const form = new FormData()

      form.append('file', file.file)

      // form.append('Authorization', this.Authorization)

      const { data: res } = await uploadAdminOne(form)
      // console.log(res,"res ");
      if (res.resultCode === 200) {
        this.$emit('update:lookUrl', res.data)
        this.$emit('update:saveUrl', res.data)
        this.$emit('upload', res.data, this.index)
      }
    },
    clear() {
      this.explainFileList = []
    },
    handlePreview() {
      const url = this.saveUrl
      axios({
        method: 'GET',
        url: process.env.VUE_APP_BASE_API + 'upload/uploadFile',
        params: { urlStr: url },
        responseType: 'blob',
        headers: {
          // 设置请求头
          token: this.Authorization
        }
      })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: `application/msword`
          })
          let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载'链接
          a.download = this.saveUrl.substring(this.saveUrl.lastIndexOf('/') + 1) //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
          document.body.removeChild(a) //释放标签
        })
        .catch(() => {
          //this.$message.error('文件下载失败')
        })
    }
  }
}
</script>

<style scoped lang="less">
.updateBtn {
  width: 72px;
  height: 33px;
  color: #7c71e1;
  background-color: rgba(112, 103, 242, 0.1);
  border-radius: 2px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 25px;
}
</style>
